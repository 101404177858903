import React from "react"
import { FastField } from "formik"
import * as I from "../Input/styled"
import * as S from "./styled"

export const Checkbox = ({ id, name, label }) => {
  return (
    <I.Input>
      <FastField name={name}>
        {({ field, form }) => {
          const error = form.errors[field.name]
          const isTouched = form.touched[field.name]

          return (
            <>
              <S.StyledCheckbox
                name={name}
                type="checkbox"
                isError={isTouched && !!error}
                {...field}
                id={id}
              />
              <S.StyledLabel htmlFor={id}>{label}</S.StyledLabel>
              <I.ErrorLabel>{isTouched ? error : ""}</I.ErrorLabel>
            </>
          )
        }}
      </FastField>
    </I.Input>
  )
}
