import styled from "styled-components"

export const Container = styled.div`
  width: ${props => props.theme.size.contentWidth};
  margin: auto;
  box-sizing: border-box;
`

export const VisuallyHiddenLabel = styled.label`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
`
