import React, { createContext, useContext } from "react"

export const SiteMetadata = createContext(null)

const SiteMetadataProvider = ({ siteMetadata, ...props }) => {
  return <SiteMetadata.Provider value={siteMetadata} {...props} />
}

export const useSiteMetadata = () => useContext(SiteMetadata)

export default SiteMetadataProvider
