import styled from "styled-components"

export const Input = styled.div`
  position: relative;
  padding-bottom: 1.25rem;
`

export const StyledInput = styled.input`
  ${props => props.theme.typography.sourceSansPro.font340};
  background-color: transparent;
  display: block;
  width: 100%;
  height: 3.4375rem;
  padding: 0 1.875rem;
  border: 1px solid ${props => props.theme.color.paleGrey};
  border-radius: 1.72rem;
  color: ${props => props.theme.color.darkGray};
  transition: border-color ${props => props.theme.animation.fast};
  appearance: none;

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    -webkit-text-fill-color: ${props => props.theme.color.black};
    box-shadow: 0 0 0px 1000px transparent inset;
    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
    transition: background-color 5000s ease-in-out 0s;
  }

  &:focus {
    outline: none;
    border-color: ${props => props.theme.color.gray};
  }

  &::placeholder {
    ${props => props.theme.typography.sourceSansPro.font340};
    color: ${props => props.theme.color.midGray};
  }

  ${({ isError, theme }) =>
    isError ? `border-color: ${theme.color.red} !important;` : ""}
`

export const ErrorLabel = styled.p`
  ${props => props.theme.typography.sourceSansPro.font140};
  color: ${props => props.theme.color.red};
  line-height: 1.25rem;
  opacity: 1;
  margin: 0;
  position: absolute;
  bottom: 0;
  left: 2rem;
  max-height: 10rem;
  overflow: hidden;
  transition: opacity ${props => props.theme.animation.slow},
    max-height ${props => props.theme.animation.slow};

  &:empty {
    opacity: 0;
    max-height: 0;
  }
`
export const SelectMarker = styled.span`
  display: block;
  position: absolute;
  right: 2rem;
  top: 1.65rem;
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-top-color: ${props => props.theme.color.midGray};
`
