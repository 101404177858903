import React from "react"
import PropTypes from "prop-types"

import * as C from "./components"
import * as S from "./styled"
import { RootLayout } from "../RootLayout"

export const IndexLayout = ({ children }) => (
  <RootLayout title="How to create Buyer Personas?">
    <C.Header />
    <S.IndexWrapper>{children}</S.IndexWrapper>
    <C.Footer />
  </RootLayout>
)

IndexLayout.propTypes = {
  children: PropTypes.node.isRequired,
}
