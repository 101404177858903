import styled from "styled-components"
import { theme } from "shared/styles/theme"

const { breakpoints } = theme

export const StyledCheckbox = styled.input`
  &[type="checkbox"] {
    position: absolute;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
  }

  &[type="checkbox"] + label {
    display: block;
    position: relative;
    padding-left: 34px;
  }

  &[type="checkbox"] + label::before {
    content: "";
    border: 1px solid ${props => props.theme.color.paleGrey};
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    margin-right: 16px;
    margin-top: 2px;
    width: 18px;
    height: 18px;
    border-radius: 3px;
    background: white;

    ${({ isError, theme }) =>
      isError ? `border-color: ${theme.color.red} !important;` : ""}
  }

  &[type="checkbox"]:checked + label::before {
    background-color: ${props => props.theme.color.royalBlue};
  }

  &[type="checkbox"]:checked + label::after {
    content: "";
    position: absolute;
    top: 6px;
    left: 2px;
    border-left: 2px solid #fff;
    border-bottom: 2px solid #fff;
    height: 6px;
    width: 13px;
    transform: rotate(-45deg);
  }

  &[type="checkbox"]:focus + label::before {
    border-color: ${props => props.theme.color.gray};
  }
`

export const StyledLabel = styled.label`
  ${props => props.theme.typography.sourceSansPro.font240};
  color: ${props => props.theme.color.midGray};
  display: block;

  @media (max-width: ${breakpoints.md}px) {
    ${props => props.theme.typography.sourceSansPro.font140};
  }

  a {
    color: ${props => props.theme.color.skyBlue};
    text-decoration: none;
    border-bottom: 2px solid ${props => props.theme.color.skyBlue};
  }
`
export const CheckboxWrapper = styled.div`
  display: flex;
`
