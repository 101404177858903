import React from "react"

import * as C from "shared/components"
import useAuth from "hooks/useAuth"

import * as S from "./styled"

const buttonOverrides = {
  paddingLeft: "1.5rem",
  paddingRight: "1.5rem",
  fontWeight: 400,
  marginLeft: "1rem",
}

export const Header = () => {
  const { isAuthenticated, logout } = useAuth()

  return (
    <S.Root>
      <S.Wrapper>
        <C.Logo covered />
        <S.ButtonsWrapper>
          {isAuthenticated && (
            <C.Button
              id="try-now-button"
              size="md"
              fontFamily="ttCommons"
              overrides={buttonOverrides}
              onClick={logout}
            >
              Logout
            </C.Button>
          )}

          <C.Button
            id="try-now-button"
            to="/persona"
            size="md"
            fontFamily="ttCommons"
            color="darkGreyBlue"
            overrides={buttonOverrides}
          >
            Try now
          </C.Button>
        </S.ButtonsWrapper>
      </S.Wrapper>
    </S.Root>
  )
}
