import React from "react"
import PropTypes from "prop-types"

import { RootLayout } from "../RootLayout"

export const Layout404 = ({ children }) => (
  <RootLayout title="404: Not found">{children}</RootLayout>
)

Layout404.propTypes = {
  children: PropTypes.node.isRequired,
}
