import { createGlobalStyle } from "styled-components"
import "fonts/fonts.css"

export const GlobalStyle = createGlobalStyle`
  html, body {
    font-family: SourceSansPro-Semibold, sans-serif;
    font-size: 16px;
    font-weight: ${props => props.theme.font.weight.normal};
    line-height: 1.6;
    color: ${props => props.theme.color.darkGray};
    background-color: ${props => props.theme.color.whiteSmoke};
    box-sizing: border-box;
  }

  *,
  *::before,
  *::after {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
  }

  .slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    user-select: none;
    touch-action: pan-y;
    width: 100%;
  }

  .slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
  }

  .slick-list:focus {
    outline: none;
  }

  .slick-list.dragging {
    cursor: pointer;
    cursor: hand;
  }

  .slick-slider .slick-track,
  .slick-slider .slick-list {
    transform: translate3d(0, 0, 0);
  }

  .slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: block;
  }

  .slick-track:before,
  .slick-track:after {
    display: table;
    content: "";
  }

  .slick-track:after {
    clear: both;
  }

  .slick-loading .slick-track {
    visibility: hidden;
  }

  .slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
  }

  .slick-slide div {
    outline: none;
  }

  [dir="rtl"] .slick-slide {
    float: right;
  }

  .slick-slide img {
    display: block;
  }

  .slick-slide.slick-loading img {
    display: none;
  }

  .slick-slide.dragging img {
    pointer-events: none;
  }

  .slick-initialized .slick-slide {
    display: block;
  }

  .slick-loading .slick-slide {
    visibility: hidden;
  }

  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }

  .slick-arrow.slick-hidden {
    display: none;
  }

  .slick-prev,
  .slick-next {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    width: 15px;
    height: 15px;
    padding: 0;
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
  }

  .slick-prev:hover,
  .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    color: transparent;
    outline: none;
  }

  .slick-prev {
    left: -25px;
    border-top: 1px solid ${props => props.theme.color.darkGray};
    border-left: 1px solid ${props => props.theme.color.darkGray};
    transform: rotate(-45deg);
  }

  .slick-next {
    right: -25px;
    border-top: 1px solid ${props => props.theme.color.darkGray};
    border-right: 1px solid ${props => props.theme.color.darkGray};
    transform: rotate(45deg);
  }

  .slick-next::before,
  .slick-prev::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 35px;
    height: 1px;
    background: ${props => props.theme.color.darkGray};
  }

  .slick-prev::before {
    transform: rotate(45deg);
    top: 83%;
    left: -5px;
  }

  .slick-next::before {
    transform: rotate(-45deg);
    top: 89%;
    left: -16px;
  }
`
