export const characteristicsOptions = [
  {
    id: 1,
    title: "Pains",
    name: "pains",
    value: "",
    placeholder:
      "What is disturbing or troubling your customer? What is blocking them from getting jobs done?",
    color: "mistyRose",
  },
  {
    id: 2,
    title: "Gains",
    name: "gains",
    value: "",
    placeholder:
      "What would make your customer happy? What would make their lives and jobs-to-be-done easier?",
    color: "lightGoldenrodYellow",
  },
  {
    id: 3,
    title: "Jobs to be done",
    name: "jobsToBeDone",
    value: "",
    placeholder:
      "What are the jobs that the customers want to achieve in their work and lives?",
    color: "lightBlue",
  },
  {
    id: 4,
    title: "Pain relievers",
    name: "painRelievers",
    value: "",
    placeholder: "How can you help customers? What problems can you eliminate?",
    color: "lightPink",
  },
  {
    id: 5,
    title: "Gain creators",
    name: "gainCreators",
    value: "",
    placeholder:
      "What can you provide your customers to help them achieve these gains?",
    color: "paleTurquoise",
  },
  {
    id: 6,
    title: "Product and services",
    name: "productAndServices",
    value: "",
    placeholder: "What are the products and services that you can offer?",
    color: "lightGreen",
  },
]

export const reachOptions = [
  { id: 1, value: "", name: "brands", label: "Brands" },
  { id: 2, value: 125, name: "referrals", label: "Referrals" },
  { id: 3, value: 125, name: "socialMedia", label: "Social Media" },
  { id: 4, value: 125, name: "traditionalAdds", label: "Traditional adds" },
  { id: 5, value: 125, name: "publicRelation", label: "Public Relation" },
  { id: 6, value: 125, name: "directSales", label: "Direct sales" },
  { id: 7, value: 125, name: "inboundContent", label: "Inbound content" },
]

export const motivationsOptions = [
  { id: 1, value: 100, name: "easyToUse", label: "Easy to use" },
  { id: 2, value: 100, name: "efficiency", label: "Efficiency" },
  { id: 3, value: 100, name: "timeSavings", label: "Time (savings)" },
  { id: 4, value: 100, name: "price", label: "Price" },
  { id: 5, value: 100, name: "social", label: "Social" },
]

export const exampleTrendString =
  "This is a hardcoded example and it shall not be printed™"

export const initialValues = {
  title: "",
  photo: null,
  photoSrc: null,
  fullName: "",
  occupation: "",
  age: "",
  sex: "",
  location: "",
  companySize: "",
  industry: "",
  education: "",
  familyStatus: "",
  skills: [
    { key: Math.random(), name: "", value: 125 },
    { key: Math.random(), name: "", value: 125 },
    { key: Math.random(), name: "", value: 125 },
  ],
  characteristics: characteristicsOptions,
  reach: reachOptions,
  motivations: motivationsOptions,
  positiveTrends: [
    {
      label: "CO2 Offset",
      value: `${exampleTrendString} 1`,
    },
    {
      label: "Planting trees",
      value: `${exampleTrendString} 2`,
    },
  ],
  negativeTrends: [
    {
      label: "Climate change",
      value: `${exampleTrendString} 3`,
    },
  ],
}

export const localStorageKey = "PERSONA_CREATOR_LOCAL_STORAGE"

export const NEWSLETTER_SUBSCRIPTION_URL =
  process.env.GATSBY_PRODUCTION === "true"
    ? "https://start-up.house/api/subscribers"
    : "https://staging.start-up.house/api/subscribers"
export const LEADS_SDH_URL =
  process.env.GATSBY_PRODUCTION === "true"
    ? "https://start-up.house/api/leads"
    : "https://staging.start-up.house/api/leads"
