import React, { useState } from "react"
import { Formik, Form } from "formik"
import * as Yup from "yup"
import Axios from "axios"

import * as C from "shared/components"
import * as O from "components/persona/constants"
import { VisuallyHiddenLabel } from "shared/styles/helpers"
import { Checkbox } from "components/persona/DownloadModal/components/Checkbox"

import * as S from "./styled"

const buttonOverrides = {
  width: "3.125rem",
  height: "3.125rem",
  padding: 0,
  marginLeft: "0.5rem",
  fontWeight: 400,
  borderRadius: "50%",
}

const NewsletterSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .required("Required"),

  isNewsletterAccepted: Yup.boolean().oneOf([true], "Required"),
})

export const Footer = () => {
  const [isError, setIsError] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)

  const handleSaveEmail = async email => {
    try {
      try {
        await Axios.post(O.NEWSLETTER_SUBSCRIPTION_URL, {
          email,
        })
      } catch (e) {
        // eslint-disable-next-line
        console.error(e)
      }

      setIsSuccess(true)
    } catch (err) {
      setIsError(true)
    }
  }

  const signupToNewsletter = ({ email }) => {
    handleSaveEmail(email)
  }

  return (
    <S.Root>
      <C.ContentWrapper>
        <S.Divider />
        <S.FooterContent>
          <S.FooterItem>
            <S.LogoMobile>
              <C.Logo customId="footer-home-button-mobile" />
            </S.LogoMobile>
            <S.LogoDesktop>
              <C.Logo vertical />
            </S.LogoDesktop>
          </S.FooterItem>
          <S.FooterItem>
            <Formik
              initialValues={{
                email: "",
                isNewsletterAccepted: false,
              }}
              onSubmit={signupToNewsletter}
              validationSchema={NewsletterSchema}
            >
              {() => (
                <Form>
                  <S.FormWrapper>
                    <S.FooterNewsletterCopyMobile>
                      Join our newsletter and get latest informations about our
                      products
                    </S.FooterNewsletterCopyMobile>
                    <S.FormLeftWrapper>
                      <S.FooterNewsletterCopy>
                        Join our newsletter and get latest informations about
                        our products
                      </S.FooterNewsletterCopy>
                      <Checkbox
                        id="agreement_checkbox"
                        name="isNewsletterAccepted"
                        label={
                          <span>
                            I agree to receive the newsletter and commercial
                            information
                            <br /> from Startup Development House{" "}
                            <a
                              href="https://start-up.house/en/privacy-policy?utm_source=persona_creator"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Privacy policy
                            </a>
                          </span>
                        }
                      />
                    </S.FormLeftWrapper>
                    <S.FooterInputRight>
                      <S.FooterInputWrapper>
                        <VisuallyHiddenLabel htmlFor="email">
                          Your email
                        </VisuallyHiddenLabel>

                        <S.Input
                          id="email"
                          name="email"
                          placeholder="Your email"
                          data-lpignore="true"
                        />

                        <C.Button
                          id="submit-newsletter-footer"
                          type="submit"
                          fontFamily="ttCommons"
                          color="darkGreyBlue"
                          overrides={buttonOverrides}
                          aria-label="Subscribe to newsletter"
                        >
                          <S.Arrow />
                        </C.Button>
                        <S.FooterErrorMessage name="email" component="p" />
                      </S.FooterInputWrapper>
                      {isError && (
                        <S.FooterError>
                          There was an error. Please try again later
                        </S.FooterError>
                      )}

                      {isSuccess && (
                        <S.FooterSuccess>
                          You have been signed up
                        </S.FooterSuccess>
                      )}
                    </S.FooterInputRight>
                  </S.FormWrapper>
                </Form>
              )}
            </Formik>
          </S.FooterItem>
        </S.FooterContent>
        <S.FooterBottom>
          <S.FooterText>
            Copyright &copy; 2020 personacreator. All rights reserved
          </S.FooterText>
          <S.FooterLinkWrapper>
            <S.FooterLink href="https://start-up.house/en/privacy-policy?utm_source=persona_creator#using-cookies">
              Cookies policy
            </S.FooterLink>
            <S.FooterLink href="https://start-up.house/en/privacy-policy?utm_source=persona_creator">
              Privacy Policy
            </S.FooterLink>
          </S.FooterLinkWrapper>
        </S.FooterBottom>
      </C.ContentWrapper>
    </S.Root>
  )
}
