import React from "react"
import PropTypes from "prop-types"

import { RootLayout } from "../RootLayout"

export const PersonaLayout = ({ children }) => (
  <RootLayout title="Create persona">{children}</RootLayout>
)

PersonaLayout.propTypes = {
  children: PropTypes.node.isRequired,
}
