import styled from "styled-components"
import { theme } from "shared/styles/theme"
import { Field, ErrorMessage } from "formik"

const {
  color: {
    white,
    paleGrey,
    lightestGray,
    darkGreyBlue,
    midGray,
    positiveTag,
    red,
  },
  breakpoints,
} = theme

export const Root = styled.footer`
  background-color: ${paleGrey};
  padding-top: 4.3rem;
  padding-bottom: 4.75rem;
  max-width: 100vw;
  overflow: hidden;

  @media (max-width: ${breakpoints.md}px) {
    padding-top: 0;
    padding-bottom: 2rem;
  }
`

export const Divider = styled.div`
  width: 100%;
  background: ${lightestGray};
  height: 1px;
`

export const FooterContent = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 60px;
  margin-bottom: 5.8rem;
  @media (max-width: ${breakpoints.md}px) {
    flex-direction: column;
  }
`

export const FooterText = styled.p`
  ${props => props.theme.typography.ttCommons.font160};
  color: ${midGray};
  padding-bottom: 0.75rem;
  padding-right: 1rem;

  @media (max-width: ${breakpoints.md}px) {
    padding-right: 0;
  }
`

export const FooterLinkWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  @media (max-width: ${breakpoints.md}px) {
    justify-content: space-between;
  }
`

export const FooterLink = styled.a`
  ${props => props.theme.typography.ttCommons.font160};
  color: ${midGray};
  text-decoration: none;
  margin-left: 1rem;
  opacity: 1;
  transition: opacity ${theme.animation.fast};

  &:hover {
    opacity: 0.75;
  }

  @media (max-width: ${breakpoints.md}px) {
    height: 48px;
    line-height: 48px;
    display: inline-block;
    margin-top: 1rem;
    margin-left: 0;
  }
`

export const FooterLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  & > a {
    opacity: 1;
    transition: opacity ${theme.animation.fast};

    &:hover {
      opacity: 0.75;
    }
  }

  @media (max-width: ${breakpoints.md}px) {
    justify-content: flex-end;
  }
`

export const FooterInputWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  position: relative;
  padding-bottom: 1.5rem;

  @media (max-width: ${breakpoints.md}px) {
    display: block;

    input {
      margin: 0;
      width: 100%;
    }

    button {
      width: 100%;
      margin-top: 1rem;
      border-radius: 25px;
    }
  }
`

export const FooterInputRight = styled.div``

export const Input = styled(Field)`
  ${props => props.theme.typography.ttCommons.font240};
  line-height: 3.125rem;
  border: none;
  border-radius: 25px;
  color: ${darkGreyBlue};
  background: ${white};
  padding: 0 2.25rem;
  outline: none;
`

export const FooterItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: ${breakpoints.md}px) {
    justify-content: center;
    padding-top: 2rem;

    &:first-child p {
      padding-right: 0;
    }

    & label {
      text-align: left;
    }

    & br {
      display: none;
    }
  }
`
export const FooterNewsletterCopy = styled.p`
  ${props => props.theme.typography.ttCommons.font440};
  max-width: 21.875rem;
  line-height: 1;
  padding-right: 1rem;
  padding-bottom: 1.4rem;
  width: 100%;

  @media (max-width: ${breakpoints.md}px) {
    display: none;
  }
`

export const FooterNewsletterCopyMobile = styled.p`
  ${props => props.theme.typography.ttCommons.font440};
  line-height: 1;
  padding-bottom: 1.4rem;
  width: 100%;
  @media (min-width: ${breakpoints.md + 1}px) {
    display: none;
  }
`

export const FooterError = styled.p`
  color: ${red};
  margin-top: 0.5rem;
  padding-left: 1.5rem;
`

export const FooterErrorMessage = styled(ErrorMessage)`
  ${props => props.theme.typography.sourceSansPro.font240};
  color: ${red};
  margin-top: 0.5rem;
  position: absolute;
  bottom: -0.2rem;
  left: 2rem;
`

export const FooterSuccess = styled.p`
  color: ${positiveTag};
  margin-top: 0.5rem;
  padding-left: 1.5rem;
`

export const FormWrapper = styled.div`
  display: flex;
  margin-right: 6rem;

  @media (max-width: ${breakpoints.lg}px) {
    margin-right: 0;
    margin-left: 6rem;
  }

  @media (max-width: ${breakpoints.md}px) {
    margin: 0;
    flex-direction: column;
  }
`

export const FormLeftWrapper = styled.div`
  @media (max-width: ${breakpoints.md}px) {
    order: 2;
  }
`

export const Arrow = styled.span`
  display: block;
  height: 2px;
  background-color: #fff;
  width: 21px;
  margin: 0 auto;
  position: relative;

  &:after {
    content: "";
    display: block;
    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff;
    position: absolute;
    width: 12px;
    height: 12px;
    right: 4px;
    bottom: -1px;
    transform: rotate(-45deg) translateY(50%);
  }
`

export const FooterBottom = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: ${breakpoints.md}px) {
    flex-direction: column;
  }
`
export const LogoMobile = styled.div`
  @media (min-width: ${breakpoints.md + 1}px) {
    display: none;
  }
`
export const LogoDesktop = styled.div`
  max-width: 100%;

  @media (max-width: ${breakpoints.md}px) {
    display: none;
  }
`
